import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'app-login-info-screen',
  templateUrl: './login-info-screen.component.html',
  styleUrls: ['./login-info-screen.component.scss'],
})
export class LoginInfoScreenComponent implements OnInit {

  constructor(
  ) {
  }

  @Output() back = new EventEmitter();

  @Input() icon: string;
  @Input() awsApiRequestId: string;
  @Input() title: string;
  @Input() text: string;
  @Input() success: boolean;
  countdown: Observable<number>;

  ngOnInit(): void {

    this.countdown = timer(0, 1000).pipe(
      take(6),
      map(counter => 5 - counter),
    );
  }

  goBack() {
    this.back.emit();
  }

}
