import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { AuthToken, CustomDate, QueryParams, Rating, User } from '../sign-in/user.schema.angular';
import { environment } from '../../environments/environment';
import { format } from 'date-fns';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {

  private window: Window;

  user: User;
  queryParams: QueryParams;
  authToken: AuthToken;
  loading = false;
  token: string;
  showRatingDialog = false;
  showRatingCard = false;

  constructor(private readonly http: HttpClient, @Inject(DOCUMENT) private document: Document) {
    this.window = this.document.defaultView;
  }

  async updateUserSettings() {
    const userSettings = {
      collectReviews: this.user.collectReviews,
      showTrustedBadge: this.user.showTrustedBadge,
      trustedBadgeDesktop: this.user.trustedBadgeDesktop,
      trustedBadgeMobile: this.user.trustedBadgeMobile,
      id: this.user.id,
      ratingSubmitted: this.user.ratingSubmitted,
      ratingDialogDontAskAgain: this.user.ratingDialogDontAskAgain,
      ratingCardClosed: this.user.ratingCardClosed,
    };
    return await this.http.post<boolean>(`${environment.apiUrl}/update/user-settings`, userSettings).pipe(take(1)).toPromise();
  }

  async setRatingSettings(rating: Rating) {
    this.user = {
      ...this.user,
      ...rating,
    };
    await this.updateUserSettings();
  }

  ratingDialogNotYetShown(): boolean {
    return this.user && (this.user?.ratingDialogDontAskAgain === '' || this.user?.ratingDialogDontAskAgain == null)
      && (this.user?.ratingSubmitted === '' || this.user?.ratingSubmitted == null);
  }

  ratingCardNotYetShow(): boolean {
    return this.user && (this.user?.ratingSubmitted === '' || this.user?.ratingSubmitted == null)
      && (this.user?.ratingCardClosed === '' || this.user?.ratingCardClosed == null);
  }

  getCurrentDate(): CustomDate {
    return format(new Date(), 'yyyy-MM-dd') as CustomDate;
  }

  submitRating(submitRating: boolean, closeRatingCard?: boolean) {
    const currentDate = this.getCurrentDate();
    let rating: Rating;
    // User submit a rating --> hide Dialog and Card
    if (submitRating) {
      rating = {ratingSubmitted: currentDate};
      this.setShowRatingCard(false);
      this.setShowRatingDialog(false);
    } else {
      // User Close Card --> hide Card
      if (closeRatingCard) {
        rating = {ratingCardClosed: currentDate};
        this.setShowRatingCard(false);
        // User Close Dialog --> hide Dialog
      } else {
        rating = {ratingDialogDontAskAgain: currentDate};
        this.setShowRatingDialog(false);
      }
    }

    this.setRatingSettings(rating);
    if (submitRating) {
      this.window.open(environment.ratingUrl, '_blank');
    }
  }

  setLoading(state: boolean) {
    this.loading = state;
  }

  setUser(user: User) {
    this.user = user;
  }

  setShowRatingCard(state: boolean) {
    this.showRatingCard = state;
  }

  setShowRatingDialog(state: boolean) {
    this.showRatingDialog = state;
  }
}
