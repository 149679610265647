<div class="main__content">
    <div class="loader">
        <div class="dynamic-content-wrapper loader__content">
            <div class="ts-trusty">
                <img class="ts-trusty__image" src="assets/images/trusty.png">
                <img class="ts-trusty__shadow" src="assets/images/trusty-shadow.png">
            </div>
            <div class="progress-bar">
                <div class="progress-bar__loader"></div>
            </div>
        </div>
    </div>
</div>
