import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationStart, Router } from '@angular/router';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { componentDestroyed, OnDestroyMixin } from '@w11k/ngx-componentdestroyed';
import { SettingsService } from './settings/settings.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends OnDestroyMixin implements OnInit, OnDestroy {

  currentRoute$: Observable<string>;

  constructor(
    private router: Router,
    public readonly translate: TranslateService,
    public settingsService: SettingsService,
  ) {
    super();
    translate.setDefaultLang('en');
  }

  async ngOnInit(): Promise<void> {
    this.currentRoute$ = this.router.events.pipe(
      takeUntil(componentDestroyed(this)),
      filter(event => event instanceof NavigationStart),
      map((val: NavigationStart) => val.url),
    );
  }

  ngOnDestroy(): void {
  }

  getWindow() {
    return window;
  }
}
