export const COUNTRIES = [
  {code: 'AT', name: 'Austria', languages: ['DE']},
  {code: 'CH', name: 'Switzerland', languages: ['FR', 'DE']},
  {code: 'DE', name: 'Germany', languages: ['DE']},
  {code: 'BE', name: 'Belgium', languages: ['NL', 'FR']},
  {code: 'ES', name: 'Spain', languages: ['ES']},
  {code: 'FR', name: 'France', languages: ['FR']},
  {code: 'GB', name: 'United Kingdom', languages: ['EN']},
  {code: 'IT', name: 'Italy', languages: ['IT']},
  {code: 'NL', name: 'The Netherlands', languages: ['NL']},
  {code: 'PL', name: 'Poland', languages: ['PL']},
  {code: 'PT', name: 'Portugal', languages: ['PT']},
];
