<img class="logo" src="assets/icons/eTrusted.svg" alt="logo">

<div class="row center">

    <mat-card *ngIf="!loginStatus.submitted && form">
        <h1>{{"LOGIN.HEADING_SIGNUP" | translate}}</h1>
        <div class="row subheader">
            <div class="divider"></div>
            <h4> {{"LOGIN.HEADING_ABOUT_SHOP" | translate}}</h4>
            <div class="divider"></div>
        </div>
        <form [formGroup]="form">
            <div class="row">
                <div class="input">
                    <p>{{"LOGIN.INPUT_SHOP_DOMAIN" | translate}}</p>
                    <input matInput formControlName="shopDomain" placeholder="{{'LOGIN.LINK' | translate}}"
                           [class.error]="form.invalid">
                </div>
                <div class="col gap input">
                    <p>{{"LOGIN.INPUT_SHOP_TARGET" | translate}}</p>
                    <select formControlName="targetMarket" name="targetMarket">
                        <option *ngFor="let country of countries"
                                [value]="country.code"
                                [selected]="country.code == form.controls.targetMarket.value">
                            {{country.name}}
                        </option>
                    </select>
                </div>
            </div>

            <div class="row">
                <div class="input">
                    <p>{{"LOGIN.INPUT_SHOP_NAME"| translate}}</p>
                    <input matInput formControlName="shopName"
                           placeholder="{{'LOGIN.PLACEHOLDER_NAME_OF_YOUR_SHOP' | translate}}"
                           [class.error]="form.invalid">
                </div>
                <div class="col gap input">
                    <p>{{"LOGIN.INPUT_SHOP_LANGUAGE" | translate}}</p>
                    <select formControlName="shopLanguage" name="shopLanguage">
                        <option *ngFor="let language of languages"
                                [value]="language.code"
                                [selected]="language.code == form.controls.shopLanguage.value"
                                [disabled]="language.disabled">
                            {{language.name}}
                        </option>
                    </select>
                </div>
            </div>

            <div class="row subheader inset">
                <div class="divider"></div>
                <h4> {{"LOGIN.HEADING_ABOUT_COMPANY" | translate}}</h4>
                <div class="divider"></div>
            </div>

            <div class="row">
                <div class="input">
                    <p>{{"LOGIN.INPUT_COMPANY_NAME" | translate}}</p>
                    <input matInput formControlName="companyName"
                           placeholder="{{'LOGIN.PLACEHOLDER_NAME_OF_YOUR_COMPANY' | translate}}"
                           [class.error]="form.invalid">
                </div>
                <div class="col input gap">
                    <p>{{"LOGIN.INPUT_COUNTRY" | translate}}</p>

                    <select formControlName="country" name="country">
                        <option *ngFor="let country of countries"
                                [value]="country.code"
                                [selected]="country.code == form.controls.country.value">
                            {{country.name}}
                        </option>
                    </select>
                </div>
            </div>

            <div class="row subheader inset">
                <div class="divider"></div>
                <h4>{{"LOGIN.HEADING_ABOUT_YOURSELF" | translate}}</h4>
                <div class="divider"></div>
            </div>

            <div class="row">
                <div class="input">
                    <p>{{"LOGIN.INPUT_FIRST_NAME" | translate}}</p>
                    <input matInput formControlName="firstName"
                           placeholder="{{'LOGIN.PLACEHOLDER_YOUR_FIRST_NAME' | translate}}"
                           [class.error]="form.invalid">
                </div>

                <div class="col input gap">
                    <p>{{"LOGIN.INPUT_LAST_NAME" | translate}}</p>
                    <input matInput formControlName="lastName"
                           placeholder="{{'LOGIN.PLACEHOLDER_YOUR_LAST_NAME' | translate}}"
                           [class.error]="form.invalid">
                </div>
            </div>

            <div class="row">
                <div class="input">
                    <p>{{"LOGIN.INPUT_EMAIL" | translate}}</p>
                    <input matInput formControlName="email" placeholder="{{'LOGIN.PLACEHOLDER_MAIL' | translate}}"
                           [class.error]="form.invalid">
                </div>

                <div class="col gap input">
                    <p class="pseudo-placeholder"></p>
                    <div class="login-info-text">
                        <img src="assets/icons/feedback-info-circle-outline.svg" alt="info-icon">
                        <span>{{"LOGIN.PASSWORD_INFO" | translate}}</span>
                    </div>
                </div>
            </div>

            <div class="submit-signin">

                <div class="terms-of-service">
                    {{"LOGIN.TERMS_OF_SERVICE_TEXT" | translate}}
                    <a target="_blank" href="{{'LOGIN.TERMS_OF_SERVICE_LINK' | translate}}"> {{"LOGIN.TERMS_OF_SERVICE_TITLE" | translate}} </a>
                    {{("LOGIN.TERMS_OF_SERVICE_TEXT_2" | translate)}}
                </div>
                <div class="col">
                    <button class="submit-signin" [disabled]="form.invalid" mat-stroked-button color="primary"
                            (click)="submit()">{{"LOGIN.BUTTON_SIGNUP_CONFIRM" | translate}}
                    </button>

                    <!-- ERROR MESSAGES -->
                    <mat-error *ngIf="form.invalid &&
                     !form.controls?.email?.hasError('alreadyExists') &&
                     !form.controls?.shopDomain?.hasError('alreadyExists') &&
                     !form.controls?.email?.hasError('email') &&
                     !form.controls?.shopDomain?.hasError('pattern')"
                    >{{"LOGIN.FORM_VALIDATION_ERROR" | translate}}
                    </mat-error>
                    <mat-error
                            *ngIf="form?.controls?.email?.hasError('alreadyExists')">{{"LOGIN.ERROR_MAIL_ALREADY_EXISTS" | translate}}
                    </mat-error>
                    <mat-error
                            *ngIf="form?.controls?.shopDomain?.hasError('alreadyExists')">{{"LOGIN.ERROR_DOMAIN_ALREADY_EXISTS" | translate}}
                    </mat-error>
                    <mat-error
                            *ngIf="form?.controls?.email?.hasError('email')">{{"LOGIN.ERROR_INVALID_MAIL_FORMAT" | translate}}
                    </mat-error>
                    <mat-error
                            *ngIf="form?.controls?.shopDomain?.hasError('pattern')">{{"LOGIN.ERROR_INVALID_DOMAIN_FORMAT" | translate}}
                    </mat-error>

                </div>
            </div>

            <div class="legal-disclaimer">
                <img src="../../assets/icons/feedback-info-circle-outline.svg">
                <div class="text">{{"LOGIN.LEGAL_DISCLAIMER" | translate}}</div>
            </div>
        </form>
    </mat-card>
    <app-login-info-screen
            *ngIf="loginStatus.submitted && loginStatus.valid"
            [awsApiRequestId]="awsApiRequestId"
            [icon]="'assets/icons/illustration-information-onboarding-complete-etrusted@3x.svg'"
            title="{{'LOGIN.SUCCESS_TITLE' | translate}}"
            text="{{'LOGIN.SUCCESS_TEXT' | translate}}"
            [success]="loginStatus.valid">
    </app-login-info-screen>
    <app-login-info-screen
            *ngIf="loginStatus.submitted && !loginStatus.valid"
            [awsApiRequestId]="awsApiRequestId"
            [icon]="'assets/icons/illustration-information-software-error@3x.svg'"
            title="{{'LOGIN.ERROR_TITLE' | translate}}"
            text="{{'LOGIN.ERROR_TEXT' | translate}}"
            [success]="loginStatus.valid"
            (back)="backToLogin()">
    </app-login-info-screen>
</div>
