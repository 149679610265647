<div class="header">
    <button class="close-button" (click)="settingsService.submitRating(false, true)">
        <mat-icon>close</mat-icon>
    </button>
</div>
<h1>{{"RATING.HEADLINE" | translate}}</h1>
<p>{{"RATING.TEXT" | translate}}</p>
<div class="col-center">
    <img src="assets/icons/collecting-feedback.png">
    <button mat-raised-button
            aria-label="Button that displays a tooltip that hides when scrolled out of the container"
            class="blue-button" (click)="settingsService.submitRating(true)">
        <span>{{"RATING.RATE_NOW" | translate}}</span></button>
</div>
