import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../settings/settings.service';

@Component({
  selector: 'app-rating',
  templateUrl: './rating-card.component.html',
  styleUrls: ['./rating-card.component.scss'],
})
export class RatingCardComponent implements OnInit {

  constructor(public readonly settingsService: SettingsService) {
  }

  ngOnInit(): void {
  }
}
