import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SignUpService } from './sign-up.service';
import { Router } from '@angular/router';
import { SettingsService } from '../settings/settings.service';
import { SignUp } from './user.schema.angular';
import { environment } from '../../environments/environment';
import { COUNTRIES } from '../CountryAndLanguageCodes/countries';
import { LANGUAGES } from '../CountryAndLanguageCodes/languages';
import { EXAMPLE_PREFILLED_DATA } from '../test-data/DUMMY-DATA';
import { startWith, take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-login',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit, OnDestroy {

  form: FormGroup;
  countries: Country[];
  languages: Country[];
  loginStatus = {
    submitted: false,
    valid: false,
  };
  subscription: Subscription;
  awsApiRequestId: string;

  constructor(private readonly fb: FormBuilder,
              private readonly signUpService: SignUpService,
              public readonly settingsService: SettingsService,
              private readonly router: Router,
              private readonly translateService: TranslateService) {
  }

  async ngOnInit(): Promise<void> {
    let preFilledSignUpData: SignUp;
    if (!environment.mockData) {
      preFilledSignUpData = await this.signUpService.getSignUpPrefilledData();
    } else {
      preFilledSignUpData = EXAMPLE_PREFILLED_DATA;
    }
    this.countries = COUNTRIES;
    this.languages = LANGUAGES;

    this.form = this.fb.group({
      shopDomain: [this.settingsService.queryParams.siteUrl, [Validators.required, Validators.pattern('https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)')]],
      shopLanguage: [this.mapLanguageCodeToLanguage(this.settingsService.queryParams.locale), Validators.required],
      shopName: [preFilledSignUpData.shopName, Validators.required],
      targetMarket: [this.mapCountryCodeToCountry(preFilledSignUpData.country), Validators.required],
      companyName: [preFilledSignUpData.companyName, Validators.required],
      country: [this.mapCountryCodeToCountry(preFilledSignUpData.country), Validators.required],
      firstName: [preFilledSignUpData.firstName, Validators.required],
      lastName: [preFilledSignUpData.lastName, Validators.required],
      email: [preFilledSignUpData.email, [Validators.required, Validators.email]],
    });

    this.subscription = this.form.controls.targetMarket.valueChanges.pipe(startWith(this.form.value.targetMarket as string)).subscribe((countryCode) => {
      let availableLanguages = [];
      try {
        availableLanguages = this.countries.find(country => country.code === countryCode).languages;
      } catch (e) {
        console.error(e);
      }
      if (!availableLanguages.includes(this.form.value.shopLanguage)) {
        this.form.patchValue({shopLanguage: availableLanguages[0]});
      }
      this.languages.forEach((language) => {
        if (!availableLanguages.includes(language.code)) {
          language.disabled = true;
        } else {
          language.disabled = false;
        }
      });
    });
    this.form.updateValueAndValidity({onlySelf: false, emitEvent: true});

    this.form.markAllAsTouched();
    this.settingsService.setLoading(false);
  }

  async submit() {
    this.settingsService.setLoading(true);
    try {
      await this.signUpService.signUpTrustedShops({
        ...this.form.getRawValue(),
        // map country Code
        targetMarket: COUNTRIES.find((country: Country) => country.code.toUpperCase() === this.form.value.targetMarket.toUpperCase())?.code,
        userLanguage: this.translateService.currentLang,
        id: this.settingsService.authToken.instanceId,
        originInstanceId: this.settingsService.authToken?.originInstanceId,
      });
      this.loginStatus.submitted = true;
      this.loginStatus.valid = true;
      setTimeout(async () => {
        await this.router.navigate(['/settings'], {queryParamsHandling: 'preserve', preserveFragment: true});
      }, 5000);
    } catch (error) {
      // MAIL ALREADY EXISTS
      console.log('error', error);
      this.awsApiRequestId = error?.headers?.get('x-amzn-requestid');
      console.log('API Gateway Request ID:', this.awsApiRequestId);
      if (error?.error?.description?.includes('Contact') && error?.error?.description?.includes('already exists.')) {

        // workaround to setError
        setTimeout(() => {
          this.form.controls.email.markAsDirty();
          this.form.controls.email.markAsTouched();
          this.form.controls.email.setErrors({alreadyExists: true});
        }, 1);

        this.form.controls.email.valueChanges.pipe(take(1)).subscribe(() => {
          this.form.controls.email.setErrors(null);
          this.form.controls.email.updateValueAndValidity();
        });
        // URL ALREADY EXISTS
      } else if (error?.error?.description?.includes('Shop') && error?.error?.description?.includes('already exists.')) {

        // workaround to setError
        setTimeout(() => {
          this.form.controls.email.markAsDirty();
          this.form.controls.email.markAsTouched();
          this.form.controls.shopDomain.setErrors({alreadyExists: true});
        }, 1);

        this.form.controls.shopDomain.valueChanges.pipe(take(1)).subscribe(() => {
          this.form.controls.shopDomain.setErrors(null);
          this.form.controls.shopDomain.updateValueAndValidity();
        });
      } else {
        this.loginStatus.submitted = true;
        this.loginStatus.valid = false;
      }
    } finally {
      this.settingsService.loading = false;
    }
  }

  mapCountryCodeToCountry(countryCode: string): string | undefined {
    return COUNTRIES.find((country: Country) => country.code.toUpperCase() === countryCode.toUpperCase())?.code;
  }

  mapLanguageCodeToLanguage(languageCode: string): string | undefined {
    return LANGUAGES.find((language: Language) => language.code.toUpperCase() === languageCode.toUpperCase())?.code;
  }

  backToLogin(): void {
    this.loginStatus.submitted = false;
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

}

export interface Country {
  code: string;
  name: string;
  countryName?: string;
  languages?: string[];
  disabled?: boolean;
}

export interface Language {
  code: string;
  name: string;
  nativeName: string;
}

type ErrorMessages = {
  [key in keyof SignUp]: {
    required: string,
    email?: string,
    pattern?: string,
    alreadyExists?: string,
  };
};
