import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { SignUpService } from './sign-up.service';
import { SettingsService } from '../settings/settings.service';
import { QueryParams } from './user.schema.angular';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';
import { EXAMPLE_AUTHTOKEN, EXAMPLE_QUERY_PARAMS } from '../test-data/DUMMY-DATA';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class SignUpGuard implements CanActivate {

  constructor(
    private readonly signUpService: SignUpService,
    private readonly settingsService: SettingsService,
    private readonly router: Router,
    private readonly translation: TranslateService,
    public dialog: MatDialog,
  ) {
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {

    this.getUrlParams(route);
    // check if backend request already done - if not get userInfo from backend
    if (!this.settingsService.user) {
      this.settingsService.setLoading(true);
      const response = await this.signUpService.getUserInfo(this.settingsService.token, this.settingsService.authToken.instanceId);

      if (response && (response as any).redirect) {
        console.log('show view to redirect to wix checkout', (response as any).redirect);
        this.signUpService.setRedirectUrl((response as any).redirect);
        this.settingsService.setLoading(false);
        await this.router.navigate(['/purchase']);
        return false;
      } else {
        this.settingsService.setUser(response);
        this.settingsService.setLoading(false);
      }
    }

    // if user already signedUp -> direct to settings else --> direct to login
    const currentUrl = route?.url[0]?.path ? route.url[0].path : 'login';

    if (!this.settingsService.authToken.vendorProductId) {
      console.error('App is not purchased');
    }
    if (this.settingsService.ratingDialogNotYetShown()) {
      this.settingsService.setShowRatingDialog(true);
    }

    if (this.settingsService.ratingCardNotYetShow()) {
      this.settingsService.setShowRatingCard(true);
    }

    switch (currentUrl) {
      // settings
      case 'settings': {
        if (this.settingsService.user) {
          return true;
        } else {
          await this.router.navigate(['/login'], {queryParams: route.queryParams});
          return false;
        }
      }
      // login/redirect to login
      default: {
        if (this.settingsService.user) {
          await this.router.navigate(['/settings'], {queryParams: route.queryParams});
          return false;
        } else {
          return true;
        }
      }
    }
  }

  private getUrlParams(route: ActivatedRouteSnapshot) {
    if (!environment.mockData) {
      // get params for identify user
      this.settingsService.queryParams = route.queryParams as QueryParams;
      // decode base64 params
      const instanceIDBase64 = route.queryParams?.instance?.split('.')[1];
      if (instanceIDBase64) {
        this.settingsService.authToken = JSON.parse(atob(instanceIDBase64));
        this.settingsService.token = route.queryParams?.instance;
      }
    } else {
// USE DUMMY DATA FOR LOCAL TESTING
      this.settingsService.queryParams = EXAMPLE_QUERY_PARAMS;
      this.settingsService.authToken = EXAMPLE_AUTHTOKEN;
    }
    // set current language
    this.translation.use(this.settingsService.queryParams.locale);
    console.log('current language', this.translation.currentLang);
    console.log('queryParams', this.settingsService.queryParams);
    console.log('authtoken', this.settingsService.authToken);
  }
}
