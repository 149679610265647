<mat-card class="login-info-screen-wrapper">
    <div class="icon">
        <img [src]="icon" alt="success-icon">
    </div>
    <div class="title">{{title}}</div>
    <div class="text">{{text}}</div>
    <div *ngIf="awsApiRequestId" class="text" style="font-style: italic; color: #9f9f9f">ID: {{awsApiRequestId}}</div>
    <div *ngIf="success" class="redirecting-timer">{{'LOGIN.SUCCESS_REDIRECT_1' | translate}} {{countdown | async}} {{'LOGIN.SUCCESS_REDIRECT_2' | translate}}</div>
    <button *ngIf="!success" (click)="goBack()">{{'LOGIN.BUTTON_ERROR_GO_BACK' | translate}}</button>
</mat-card>
