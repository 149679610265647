import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SignUpComponent } from './sign-up.component';
import { MatCardModule } from '@angular/material/card';
import { HttpClientModule } from '@angular/common/http';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { LoginInfoScreenComponent } from './login-info-screen/login-info-screen.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SpinnerModule } from '../spinner/spinner.module';



@NgModule({
  declarations: [SignUpComponent, LoginInfoScreenComponent],
  imports: [
    CommonModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    FormsModule,
    MatCardModule,
    HttpClientModule,
    MatSelectModule,
    TranslateModule,
    MatProgressSpinnerModule,
    SpinnerModule,
  ],
})
export class SignUpModule { }
