import { Component, OnInit } from '@angular/core';
import { SettingsService } from './settings.service';
import { TranslateService } from '@ngx-translate/core';
import { RatingDialogComponent } from '../rating/rating-dialog/rating-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {

  trustBadgePosition: { value: string, viewValue: string }[] = [
    {value: 'left', viewValue: 'SETTINGS.LEFT'},
    {value: 'right', viewValue: 'SETTINGS.RIGHT'},
  ];

  constructor(public readonly settingsService: SettingsService, public translate: TranslateService,
              private readonly dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.settingsService.setLoading(false);
    this.openRatingDialogWithDelay();
  }

  async updateUserSettings() {
    await this.settingsService.updateUserSettings();
  }

  goToReviewBox() {
    window.open(`https://app.etrusted.com/?lang=${this.translate.currentLang}_${this.translate.currentLang.toUpperCase()}`, '_blank');
  }

  private openRatingDialogWithDelay() {
    setTimeout(() => {
      if (this.settingsService.ratingDialogNotYetShown()) {
        this.openDialog();
      }
    }, environment.delayBeforeOpenRatingDialog);
  }

  private openDialog() {
    this.dialog.open(RatingDialogComponent, {
      data: {},
      backdropClass: 'backdrop-background',
      width: '600px',
      disableClose: true,
      position: {
        top: '200px',
      },
    });
  }


}
