import { Component, OnInit } from '@angular/core';
import { SignUpService } from '../sign-in/sign-up.service';

@Component({
  selector: 'app-purchase',
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.scss'],
})
export class PurchaseComponent implements OnInit {
  public redirectUrl: string;

  constructor(
    private readonly signUpService: SignUpService,
  ) {
  }

  ngOnInit(): void {
    this.redirectUrl = this.signUpService.getRedirect();
  }

  goToRedirectUrl() {
    window.open(this.redirectUrl, '_blank')
  }
}
