import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {take} from 'rxjs/operators';
import {SignUp, User} from './user.schema.angular';
import {SettingsService} from '../settings/settings.service';
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root',
})
export class SignUpService {
  private redirect: string;

  constructor(private readonly http: HttpClient, private readonly settings: SettingsService) {
  }

  signUpTrustedShops(user: User) {
    return this.http.post<any>(`${environment.apiUrl}/sign-up-trusted-shops`, user).pipe(take(1)).toPromise();
  }

  async getUserInfo(token: string, instanceId: string): Promise<User> {
    return await this.http.get<User>(`${environment.apiUrl}/get/user-settings/${token}/${instanceId}`).pipe(take(1)).toPromise();
  }

  async getSignUpPrefilledData(): Promise<SignUp> {
    return await this.http.get<SignUp>(`${environment.apiUrl}/prefilled-sign-up/${this.settings.authToken.instanceId}/${this.settings.authToken.siteOwnerId}`).pipe(take(1)).toPromise();
  }

  setRedirectUrl(redirect: string) {
    this.redirect = redirect;
  }

  getRedirect() {
    return this.redirect;
  }
}
