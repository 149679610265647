import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-helping-hand',
  templateUrl: './helping-hand.component.html',
  styleUrls: ['./helping-hand.component.scss']
})
export class HelpingHandComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
