import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SignUpComponent } from './sign-in/sign-up.component';
import { SettingsComponent } from './settings/settings.component';
import { SignUpGuard } from './sign-in/sign-up.guard';
import { PurchaseComponent } from './purchase/purchase.component';

const routes: Routes = [
  {
    path: '', component: SignUpComponent, canActivate: [SignUpGuard],
  },
  {
    path: 'purchase', component: PurchaseComponent,
  },
  {
    path: 'login', component: SignUpComponent, canActivate: [SignUpGuard],
  },
  {
    path: 'settings', component: SettingsComponent, canActivate: [SignUpGuard],
  },
  {
    path: '**', component: SettingsComponent, canActivate: [SignUpGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
