export const LANGUAGES = [
  {code: 'DE', name: 'Deutsch'},
  {code: 'EN', name: 'English (GB)'},
  {code: 'FR', name: 'Français'},
  {code: 'ES', name: 'Español'},
  {code: 'NL', name: 'Nederlands'},
  {code: 'PL', name: 'Polski'},
  {code: 'IT', name: 'Italiano'},
  {code: 'PT', name: 'Português'},
];
