    <div class="header">
        <div class="action-container">
            <div class="logo-wrapper">
                <img src="assets/icons/eTrusted.svg" alt="logo">
            </div>
            <button mat-raised-button
                    aria-label="Button that displays a tooltip that hides when scrolled out of the container"
                    class="example-button">
                <img src="assets/icons/action-open-in-new-tab.svg" alt="open">
                <span (click)="goToReviewBox()">{{"SETTINGS.BUTTON_REVIEW_INBOX" | translate}}</span>
                <div class="tooltip">
                    <div class="triangle">
                        <div class="inner-triangle"></div>
                    </div>
                    {{"SETTINGS.BUTTON_REVIEW_INBOX_TOOLTIP" | translate}}
                </div>
            </button>
        </div>
        <p class="text">{{"SETTINGS.TRUST_TEXT" | translate}}</p>
    </div>

    <mat-card>
        <div class="icon-column">
            <img src="assets/icons/illustration-feature-related-invites-active@3x.svg" alt="">
        </div>
        <div class="content-column">
            <div class="select-header wrap-toggle">
                <h1> {{"SETTINGS.AUTO_COLLECT_TITLE" | translate}}</h1>
                <div class="row end">
                    <div *ngIf="settingsService?.user?.collectReviews"> {{"SETTINGS.ACTIVE" | translate}}</div>
                    <div *ngIf="!settingsService?.user?.collectReviews">{{"SETTINGS.INACTIVE" | translate}}</div>
                    <mat-slide-toggle [(ngModel)]="settingsService.user.collectReviews"
                                      (ngModelChange)="updateUserSettings()"
                    ></mat-slide-toggle>
                </div>
            </div>
            <p>{{'SETTINGS.AUTO_COLLECT_DESCRIPTION_1' | translate}}</p>
            <p [innerHTML]="'SETTINGS.AUTO_COLLECT_DESCRIPTION_2' | translate"></p>
        </div>

    </mat-card>

    <mat-card>
        <div class="icon-column">
            <img src="assets/icons/illustration-feature-related-reputation-manager-active@3x.svg" alt="">
        </div>
        <div class="content-column">
            <h1>{{"SETTINGS.TRUST_BADGE_TITLE_1" | translate}}</h1>
            <p>{{"SETTINGS.TRUST_BADGE_DESCRIPTION_1" | translate}}</p>

            <hr class="divider">

            <div class="select-header">
                <h1>{{"SETTINGS.TRUST_BADGE_TITLE_2" | translate}}</h1>
                <div class="row end">
                    <div *ngIf="settingsService?.user?.showTrustedBadge"> {{"SETTINGS.ACTIVE" | translate}}</div>
                    <div *ngIf="!settingsService?.user?.showTrustedBadge">{{"SETTINGS.INACTIVE" | translate}}</div>
                    <mat-slide-toggle [(ngModel)]="settingsService.user.showTrustedBadge"
                                      (ngModelChange)="updateUserSettings()"
                    ></mat-slide-toggle>
                </div>
            </div>

            <p>{{"SETTINGS.TRUST_BADGE_DESCRIPTION_2" | translate}}</p>

            <img class="preview-image" src="assets/images/marketing-trustbadge-en.png">

            <table>
                <tr>
                    <td>
                        <div class="select-label">{{"SETTINGS.PLACEMENT_DESKTOP_USER" | translate}}</div>
                    </td>
                    <td>
                        <select [(ngModel)]="settingsService.user.trustedBadgeDesktop"
                                [disabled]="!settingsService.user.showTrustedBadge"
                                (change)="updateUserSettings()">
                            <option *ngFor="let position of trustBadgePosition"
                                    [value]="position.value"
                                    [selected]="position.value == settingsService.user.trustedBadgeDesktop">
                                {{position.viewValue | translate}}
                            </option>
                        </select>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="select-label">{{"SETTINGS.PLACEMENT_MOBILE_USER" | translate}}</div>
                    </td>
                    <td>
                        <select [(ngModel)]="settingsService.user.trustedBadgeMobile"
                                [disabled]="!settingsService.user.showTrustedBadge"
                                (change)="updateUserSettings()">
                            <option *ngFor="let position of trustBadgePosition"
                                    [value]="position.value"
                                    [selected]="position.value == settingsService.user.trustedBadgeMobile">
                                {{position.viewValue | translate}}
                            </option>
                        </select>
                    </td>
                </tr>
            </table>

        </div>

    </mat-card>
