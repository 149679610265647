import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RatingDialogComponent } from './rating-dialog/rating-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { RatingCardComponent } from './rating-card/rating-card.component';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [RatingDialogComponent, RatingCardComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    TranslateModule,
  ],
  exports: [RatingDialogComponent, RatingCardComponent],
})
export class RatingDialogModule { }
