<div class="row">
    <div class="col">
        <div class="app-wrapper" [hidden]="settingsService.loading">
            <router-outlet></router-outlet>
            <div class="imprint">{{"GENERAL.IMPRINT_TEXT" | translate}}
                <a target="_blank"
                   href="https://business.trustedshops.com/consent"> {{"GENERAL.IMPRINT_NAME" | translate}}</a>
            </div>
        </div>

        <div class="col">
            <div class="helping-hand-container"
                 *ngIf="getWindow().location.href.indexOf('/settings') > 0 && !settingsService.loading">
                <app-helping-hand></app-helping-hand>
            </div>

            <div class="permanent-rating" *ngIf="!settingsService.loading && settingsService.user"
                 (click)="settingsService.submitRating(true)">
                <p>{{"RATING.FOOTER_TEXT" | translate}} <a href="#" target="_blank">{{"RATING.FOOTER_RATE_US" | translate}}</a>
                </p></div>
        </div>
    </div>

    <mat-card class="app-rating" *ngIf="settingsService.showRatingCard && !settingsService.loading">
        <app-rating></app-rating>
    </mat-card>
</div>
<app-spinner [hidden]="!settingsService.loading"></app-spinner>

