<h1>{{"RATING.HEADLINE" | translate}}</h1>
<p>{{"RATING.TEXT" | translate}}</p>
<div class="col-center">
    <img src="../../../assets/icons/collecting-feedback@2x.png">
    <button mat-raised-button
            aria-label="Button that displays a tooltip that hides when scrolled out of the container"
            class="blue-button" [mat-dialog-close]="true" (click)="settingsService.submitRating(true)">
        <span>{{"RATING.RATE_NOW" | translate}}</span>
    </button>
    <a class="close-button" [mat-dialog-close]="true" (click)="settingsService.submitRating(false)">
        <a>{{"RATING.MAYBE_LATER" | translate}}</a></a>
</div>
